import userRepository from '@/repositories/userRepository';
const local_countries = JSON.parse(localStorage.getItem('local_countries'));

const state = {
  list_countries: local_countries || null
};

const getters = {
  countries: state => state.list_countries
};

const actions = {
  async getCountries({ commit, state }) {
    if (!state.list_countries) {
      const { data } = await userRepository.getListCountries();
      localStorage.setItem('local_countries', JSON.stringify(data));
      commit('setListcountries', data);
    }
  }
};

const mutations = {
  setListcountries(state, listCountries) {
    state.list_countries = listCountries;
  }
};

export const countries = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
