import { createRouter, createWebHistory } from 'vue-router';

const Login = () => import(/* webpackChunkName: "login" */ '../views/Login.vue');
const Body = () => import(/* webpackChunkName: "body" */ '../components/structure/body.vue');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard.vue');
const Eans = () => import(/* webpackChunkName: "eans" */ '../components/eans/index.vue');
const Invoices = () => import(/* webpackChunkName: "invoices" */ '../components/invoices/index.vue');
const Orders = () => import(/* webpackChunkName: "orders" */ '../components/orders/index.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ '../components/profile/index.vue');

const routes = [
  {
    path: '/',
    component: Body,
    children: [
      { path: '', component: Dashboard, name: 'Home' },
      { path: 'eans', component: Eans, name: 'Eans' },
      { path: 'billing', component: Invoices, name: 'Billing' },
      { path: 'orders', component: Orders, name: 'Orders' },
      { path: 'profile', component: Profile, name: 'Profile' }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  { path: '/:pathMatch(.*)*', redirect: { name: 'Home' } } //404
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token_session');
  if (authRequired && !loggedIn) {
    return next('/login');
  } else if (!authRequired && loggedIn) {
    return next('/');
  } else {
    next();
  }
});

export default router;
