import userRepository from '@/repositories/userRepository';

const state = {
  orders: []
};

const getters = {
  listOrders: state => state.orders
};

const actions = {
  async getListOrders({ commit }) {
    const list = await userRepository.getMeOrders();
    commit('updateListOrders', list);
  }
};

const mutations = {
  updateListOrders(state, list) {
    state.orders = list;
  }
};

export const orders = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
