<template lang="pug">
h1.font-bold.text-gray-700.mb-3 {{ title }}
</template>
<script>
export default {
  name: "structureH1",
  props: {
    title: String,
  },
};
</script>
<style lang="scss" scoped>
</style>