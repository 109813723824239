<template lang="pug">
.flex.flex-col
  label {{ label }}
  input(
    :type="typeInput",
    :name="name",
    :value="modelValue",
    @input="$emit('update:modelValue', $event.target.value)"
  )
</template>
<script>
export default {
  name: "structure_input",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [String, Number],
    },
    label: String,
    name: String,
    typeInput: {
      type: String,
      default: "text",
    },
  },
};
</script>
<style lang="scss" scoped>
input {
  @apply rounded border border-gray-200 bg-gray-50 px-3 py-2 outline-none;
  font-size: 13px;
}
</style>