<template lang="pug">
.boxcard
  slot
</template>
<script>
export default {
  name: "cardbody",
};
</script>
<style lang="scss">
.boxcard {
  background-color: #fff;
  border: 1px solid #dee5fc;
  border-radius: 5px;
  box-shadow: 0px 10px 20px 0px rgba(46, 91, 255, 0.07);
  padding: 1rem;
  margin-bottom: 2rem;
  b {
    @apply text-gray-400;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  p {
    font-size: 14px;
    @apply text-gray-600;
  }

  table {
    thead {
      background-color: #fff;
      border-bottom: 1px solid #dee5fc;
      th {
        border: none;
        text-transform: uppercase;
        font-size: 12px;
        @apply text-gray-400 py-3;
      }
    }
    tbody {
      tr {
        font-weight: 500;
        font-size: 15px;
        @apply text-gray-500;
        td {
          border-style: none;
          padding: 0.5rem;
          font-size: 14px;
        }

        .button-group {
          font-size: 12px;
          div + div {
            margin-left: 0.5rem;
          }
          div {
            @apply bg-blue-500 py-1 px-2 rounded text-white cursor-pointer;
            &.disabled {
              @apply opacity-50 cursor-default;
            }
          }
        }

        td:last-child {
          text-align: center;
        }
      }
      tr:nth-of-type(odd) {
        @apply bg-gray-100;
      }
    }
  }
}
</style>