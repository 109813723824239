import userRepository from '@/repositories/userRepository';

const state = {
  user: null,
  profileBilling: null
};

const getters = {
  userData: state => state.user,
  userProfileBilling: state => state.profileBilling,
  isValidAutoInvoice: state => state.profileBilling?.vat_is_valid
};

const actions = {
  async getInfoUser({ commit }) {
    const { user, profileBilling } = await userRepository.getFullDataUser();
    commit('updateUserData', user);
    commit('updateProfileBilling', profileBilling);
  }
};

const mutations = {
  updateUserData(state, userData) {
    state.user = userData;
  },
  updateProfileBilling(state, userProfileBilling) {
    state.profileBilling = userProfileBilling;
  }
};

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
