import { createStore } from 'vuex';
import { auth } from './auth.store';
import { countries } from './countries.store';
import { orders } from './orders.store';
import { eans } from './eans.store';
import { user } from './user.store';

export default createStore({
  modules: {
    auth,
    countries,
    orders,
    eans,
    user
  }
});
