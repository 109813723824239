import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignLeft,
  faThLarge,
  faFolderOpen,
  faBarcode,
  faFileAlt,
  faUserEdit,
  faUserSlash,
  faLightbulb,
  faChevronDown,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

library.add(faAlignLeft, faThLarge, faFolderOpen, faBarcode, faFileAlt, faUserEdit, faUserSlash, faLightbulb, faChevronDown, faTimesCircle);
