import Api from './Repository';
import { forceFileDownload } from './file_downloader.helper';

const RESOURCE = '/user';

export default {
  async getListCountries() {
    return await Api().get(`${RESOURCE}/countries`);
  },

  async getFullDataUser() {
    const { data } = await Api().get(`${RESOURCE}/me`);
    return { user: data.user, profileBilling: data.profileBilling };
  },

  async updateMe(data) {
    await Api().put(`${RESOURCE}/me`, {
      ...data
    });
  },

  async getMeOrders() {
    const { data } = await Api().get(`${RESOURCE}/me/orders`);
    return data.orders;
  },

  async getMeEans() {
    const { data } = await Api().get(`${RESOURCE}/me/eans`);
    return data.eans;
  },

  async updateMeEan({ ean_id, is_active, data_1, data_2 }) {
    const { data } = await Api().put(`${RESOURCE}/me/eans/${ean_id}`, {
      is_active,
      data_1,
      data_2
    });
    return data;
  },

  async getMeInvoices() {
    const { data } = await Api().get(`${RESOURCE}/me/invoices`);
    return data.invoices;
  },

  async getMeInvoicePDF(invoice_id) {
    const { data } = await Api().get(`${RESOURCE}/me/invoices/${invoice_id}/download`, { responseType: 'blob' });
    forceFileDownload(data, 'invoice.pdf');
  },

  async downloadXLSX(order_id) {
    const { data } = await Api().get(`${RESOURCE}/me/orders/${order_id}/xlsx`, { responseType: 'blob' });
    forceFileDownload(data, 'list_eans.xlsx');
  },

  async downloadCSV(order_id) {
    const { data } = await Api().get(`${RESOURCE}/me/orders/${order_id}/csv`, { responseType: 'blob' });
    forceFileDownload(data, 'list_eans.csv');
  },

  async sendOrderByEmail(order_id) {
    return await Api().get(`${RESOURCE}/me/orders/${order_id}/email`);
  }
};
