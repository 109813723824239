import userRepository from '@/repositories/userRepository';

const state = {
  eans: [],
  filter: null,
  id_order: null,
  query_search: null
};

const getters = {
  listEans: state => {
    let listEans = [...state.eans];
    if (state.filter !== null)
      listEans = listEans.filter(ean => {
        const chech_state = ean.data_user_active === null ? false : ean.data_user_active;
        return chech_state == state.filter;
      });
    if (state.id_order !== null) listEans = listEans.filter(ean => ean.id_order == state.id_order);
    if (state.query_search !== null)
      listEans = listEans.filter(
        ean =>
          (ean.data_user_info_1 && ean.data_user_info_1.includes(state.query_search)) ||
          (ean.data_user_info_2 && ean.data_user_info_2.includes(state.query_search))
      );
    return listEans;
  },
  totalEans: state => state.eans.length,
  totalEansUsed: state => state.eans.filter(ean => ean.data_user_active == true).length,
  totalEansNotUsed: state => state.eans.filter(ean => ean.data_user_active == false || ean.data_user_active == null).length
};

const actions = {
  async getListEans({ commit }) {
    const list = await userRepository.getMeEans();
    commit('updateListEans', list);
  }
};

const mutations = {
  updateListEans(state, list) {
    state.eans = list;
  },
  setQuerySearch(state, query) {
    console.log(query);
    state.query_search = query == '' ? null : query;
  },
  setQueryIdOrder(state, id) {
    state.id_order = id;
  },
  setQueryFilter(state, filter) {
    state.filter = filter;
  }
};

export const eans = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
