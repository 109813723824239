export function forceFileDownload(dataFile, nameFile) {
  if (navigator.msSaveBlob) {
    // IE10+ : (has Blob, but not a[download] or URL)
    return navigator.msSaveBlob(new Blob([dataFile]), nameFile);
  } else {
    const url = window.URL.createObjectURL(new Blob([dataFile]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', nameFile); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
}
