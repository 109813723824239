import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { setConfi18n } from './locale/i18n.config';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Toaster from '@meforma/vue-toaster';
import structureH1 from '@/components/structure/h1';
import structureCardBody from '@/components/structure/cardbody';
import structurebutton from '@/components/structure/button';
import structureInput from '@/components/structure/input';
import structureInputSelect from '@/components/structure/inputSelect';
import './plugins/font-awesome';

import './assets/index.css';

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('structure-h1', structureH1);
app.component('structure-card', structureCardBody);
app.component('structure-button', structurebutton);
app.component('structure-input', structureInput);
app.component('structure-input-select', structureInputSelect);

app
  .use(store)
  .use(setConfi18n())
  .use(router)
  .use(Toaster, {
    position: 'bottom',
    queue: true
  })
  .mount('#app');
