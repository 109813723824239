<template lang="pug">
.flex.flex-col.w-full.relative
  label {{ label }}
  .input-text.flex.justify-between.items-center.cursor-pointer(
    @click="show = !show"
  )
    | {{ modelValue.text }}
    .transform-gpu.transition.transition-transform.ease-in-out(
      :class="{ '-rotate-180': show }"
    )
      font-awesome-icon.text-blue-500(:icon="['fas', 'chevron-down']")
  .box-select(v-show="show", :class="position")
    ul
      li(
        v-for="option in listOptions",
        :key="option.value",
        @click="setOption(option)"
      ) {{ option.text }}
</template>

<script>
export default {
  name: "inputSelect",
  emits: ["update:modelValue"],
  props: {
    listOptions: {
      type: Array,
    },
    modelValue: {
      type: Object,
    },
    position: {
      type: String,
      default: "top", //top, bottom
    },
    label: String,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    setOption(value) {
      this.show = false;
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  text-transform: uppercase;
  @apply text-gray-400 pb-1;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
}
.input-text {
  @apply rounded border border-gray-200 bg-gray-50 px-3 py-2 outline-none;
  font-size: 13px;
}

ul li {
  &:last-child {
    @apply border-none;
  }
  &:hover {
    @apply bg-blue-50 cursor-pointer;
  }
  @apply py-3 px-4 border-b border-gray-100 text-gray-600 font-bold;
}

.box-select {
  @apply flex flex-col bg-white shadow-xl rounded-xl absolute w-full overflow-hidden origin-bottom border border-gray-100 max-h-80 overflow-y-auto;

  &.top {
    @apply bottom-14 origin-bottom;
  }
  &.bottom {
    @apply top-16 origin-top;
  }
}
</style>
