import axios from 'axios';

export default () =>
  axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 36000,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token_session')}`
    }
  });
