<template lang="pug">
.text-center.rounded.p-3.font-bold.transition.cursor-pointer(class="hover:opacity-80") {{ text }}
</template>
<script>
export default {
  name: "structure_button",
  props: {
    text: String,
  },
};
</script>
<style lang="scss" scoped>
</style>