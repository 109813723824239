import authRepository from '@/repositories/authRepository';
import router from '@/router';

const token = localStorage.getItem('token_session');
const state = {
  loggedIn: token ? true : false,
  loggin: false,
  error: null
};

const getters = {
  isLoading: state => state.loggin,
  getError: state => state.error
};

const actions = {
  async login({ commit }, { email, password }) {
    commit('setLoading', true);
    commit('setError', null);
    authRepository
      .login(email, password)
      .then(({ access_token }) => {
        localStorage.setItem('token_session', access_token);
        router.push('/');
      })
      .catch(e => {
        switch (e.response.data.message) {
          case 'password_error':
            commit('setError', 'password');
            break;
          case 'user_not_exists':
            commit('setError', 'email');
            break;
          default:
            commit('setError', 'any');
        }
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  async logout() {
    localStorage.removeItem('token_session');
    router.push('/login');
  }
};

const mutations = {
  setLoading(state, isLoading) {
    state.loggin = isLoading;
  },
  setError(state, error) {
    state.error = error;
  }
};

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
